import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LandingPage from './components/landingpage';
import Login from './components/auth/login';
import ReferralLink from './components/auth/referal/ReferralLink';
import Register from './components/auth/register';
import AllListings from './components/listings/alllistings';
import ListingDetail from './components/listings/listingdetail';
import UpdateProfile from './components/profile/update/update';
import AddPortfolio from './components/profile/portfolio/add';
import CategoryGallery from './components/landingpage/CategoryGallery';
import ListingsBySubcategory from './components/listings/ListingsBySubcategory'; // Import new component
import CreateListing from './components/listings/createlisting'; // Adjust the path if needed
import MyMessages from './components/messages/mymessages';
import Chat from './components/messages/chat';
import Header from './components/header';
import { SolanaWalletProvider } from './SolanaWalletProvider';
import UserProfilePage from './components/profile/UserProfilePage'; // Adjust the path
import ClientPayment from './components/listings/payment/clientPayment'; // Import the ClientPayment component
import OrderTracking from './components/tracking/orderTracking'; // Import the OrderTracking component
import Project from './components/tracking/Project'; // Import Project component
import Footer from './components/footer';
import AboutUs from './components/footer/aboutus';
import Careers from './components/footer/careers';
import ContactUs from './components/footer/contactus';
import FAQ from './components/footer/faq';
import PrivacyPolicy from './components/footer/privacypolicy';
import TermsOfService from './components/footer/termsofservice';
import ManageTrendingListings from './components/manage/ManageTrendingListings'
import ReviewForm from './components/reviews/ReviewForm';
import { NotificationProvider } from './contexts/notificationContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import FreelancerOrders from './components/orders/freelancerOrders';
import SubscribeToPremium from './components/listings/payment/Premium';
import BackgroundWrapper from './components/landingpage/BackgroundWrapper';
import ClientUploadDetails from './components/orders/clientuploadDetails';
import PurchaseConfirmation from './components/orders/purchaseConfirmation';
import ClientUploadConfirmation from './components/orders/clientuploadConfirmation';
import Admin from './components/auth/login/Admin';
import VerifyEmail from './components/profile/update/VerifyEmail'; // Import the new component
import ReviewDetail from './components/reviews/ReviewDisplay';
import MedalsPage from './components/hustlermedals/MedalsPage';
import Orders from './components/orders/Orders';
import FreelancerDashboard from './components/analytics/FreelancerDashboard';
import DisputeCenter from './components/disputes/DisputeCenter';
import DisputePage from './components/disputes/DisputePage';
import Dashboard from './components/adminDashboard/Dashboard';
import AdminRoute from './routes/AdminRoute';
import PaymentDistributionManagement from './components/adminDashboard/dashboardComponents/pages/PaymentDistributionManagement';

import { Buffer } from 'buffer';
window.Buffer = Buffer;

function App() {
  return (
    <SolanaWalletProvider>
     <NotificationProvider>
      <>
        <BackgroundWrapper>
        <Header />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
	  <Route path="/admin" element={<Admin />} />
	  <Route path="/register" element={<Register />} />
          <Route path="/all-listings" element={<AllListings />} />
          <Route path="/listing/:id" element={<ListingDetail />} />
          <Route path="/update-profile" element={<UpdateProfile />} />
          <Route path="/my-messages" element={<MyMessages />} />
          <Route path="/add-portfolio" element={<AddPortfolio />} />
          <Route path="/create-listing" element={<CreateListing />} />
          <Route path="/chat/:messageId" element={<Chat />} />
          <Route path="/" element={<CategoryGallery />} />
          <Route path="/listings/:subcategoryName" element={<ListingsBySubcategory />} /> {/* New route */}
          <Route path="/profile/:userId" element={<UserProfilePage />} />
          <Route path="/payment-for-service/:listingId" element={<ClientPayment />} /> {/* New PaymentForService route */}
          <Route path="/order-tracking/:orderID" element={<OrderTracking />} /> {/* New OrderTracking route */}
          <Route path="/project/:orderID" element={<Project />} /> {/* Add Project route */}
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/faq" element={<FAQ />} />
	  <Route path="/test" element={<Login />} />
          <Route path="/referal" element={<ReferralLink />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/manage-trending" element={<ManageTrendingListings />} />
	  <Route path="*" element={<LandingPage />} />
	  <Route path="/premium" element={<SubscribeToPremium />} />i
          <Route path="/reviews/new/:listingId" element={<ReviewForm />} />
	  <Route path="/freelancer-orders" element={<FreelancerOrders />} />
	  <Route path="/project-details/:projectId" element={<ClientUploadDetails />} />
          <Route path="/purchase-confirmation" element={<PurchaseConfirmation />} />
	  <Route path="/review/:reviewId" element={<ReviewDetail />} /> {/* New review detail route */}
	  <Route path="/client-upload-confirmation" element={<ClientUploadConfirmation />} />
          <Route path="/verify-email/:verifyId" element={<VerifyEmail />} /> {/* Add this line */}
	  <Route path="/medals" element={<MedalsPage />} />
	  <Route path="/orders" element={<Orders />} />
	  <Route path="/dashboard" element={<FreelancerDashboard />} />
          <Route path="/dispute/:disputeNumber" element={<DisputePage />} />
              <Route path="/admin-dashboard" element={
                <AdminRoute>
                  <Dashboard />
                </AdminRoute>
              } />
              <Route path="/admin-dashboard/payment-distribution" element={
                <AdminRoute>
                  <PaymentDistributionManagement />
                </AdminRoute>
              } />
	</Routes>
        <Footer /> {/* Add Footer component */}
        <ToastContainer />
	</BackgroundWrapper>
      </>
     </NotificationProvider>
    </SolanaWalletProvider>
  );
}

export default App;
