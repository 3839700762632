import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/authContext';
import { useWallet } from '@solana/wallet-adapter-react';
import { FaRegCommentDots, FaBell } from 'react-icons/fa';
import MyMessagesDropDown from '../messages/MyMessagesDropDown';
import { useMediaQuery } from 'react-responsive';
import { useNotification } from '../../contexts/notificationContext';
import { db } from '../../firebase/firebase';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import SidebarModal from './SidebarModal';
import NotificationDropdown from '../notifications/NotificationDropdown';
import NotificationPopup from '../notifications/NotificationPopup';
import './Header.css';

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [messagesDropdownOpen, setMessagesDropdownOpen] = useState(false);
  const [notificationsDropdownOpen, setNotificationsDropdownOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [latestNotification, setLatestNotification] = useState(null);
  const { currentUser, logout } = useAuth();
  const { notifications, markAsRead } = useNotification();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { connected, publicKey } = useWallet();

  const menuRef = useRef(null);
  const messagesMenuRef = useRef(null);
  const notificationsMenuRef = useRef(null);

  useEffect(() => {
    if (!currentUser) return;

    const messagesQuery = query(
      collection(db, 'messages'),
      where('participants', 'array-contains', currentUser.uid)
    );

    const unsubscribe = onSnapshot(messagesQuery, (messagesSnapshot) => {
      const messagesData = messagesSnapshot.docs.map(doc => doc.data());
      const hasUnread = messagesData.some(message =>
        message.messages.some(msg => !msg.isRead && msg.senderId !== currentUser.uid)
      );
      setHasUnreadMessages(hasUnread);
    });

    return () => unsubscribe();
  }, [currentUser]);

  useEffect(() => {
    if (notifications.length > 0) {
      const sortedNotifications = [...notifications].sort((a, b) =>
        b.timestamp.toMillis() - a.timestamp.toMillis()
      );

      const newestNotification = sortedNotifications[0];
      if (!latestNotification || newestNotification.id !== latestNotification.id) {
        setLatestNotification(newestNotification);
      }
    }
  }, [notifications]);

  const handleNotificationClose = () => {
    setLatestNotification(null);
  };

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const toggleMessagesDropdown = () => setMessagesDropdownOpen(!messagesDropdownOpen);
  const toggleNotificationsDropdown = () => setNotificationsDropdownOpen(!notificationsDropdownOpen);
  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
    if (messagesMenuRef.current && !messagesMenuRef.current.contains(event.target)) {
      setMessagesDropdownOpen(false);
    }
    if (notificationsMenuRef.current && !notificationsMenuRef.current.contains(event.target)) {
      setNotificationsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleNotificationClick = async (notification) => {
    await markAsRead(notification.id);
    navigate(notification.url);
  };

  const unreadNotifications = notifications.some(notification => !notification.read);

  return (
    <>
      <header className="header-container bg-gradient-to-b from-gray-800 via-gray-900 to-black text-white">
        <nav className="header-nav flex justify-between items-center">
          <div className="flex items-center">
            <Link to="/home">
              <img
                src={`/logovector.webp`}
                alt="Logo"
                className="header-logo w-32 h-auto" // Ensure the logo is responsive
              />
            </Link>
          </div>
          <div className="relative flex items-center ml-auto space-x-4">
            {currentUser && (
              <>
                <div className="relative">
                  <button
                    onClick={toggleNotificationsDropdown}
                    className="notifications-button relative text-xl"
                  >
                    <FaBell />
                    {unreadNotifications && (
                      <span className="absolute top-0 right-0 block h-2 w-2 bg-red-600 rounded-full"></span>
                    )}
                  </button>
                  {notificationsDropdownOpen && (
                    <div
                      ref={notificationsMenuRef}
                      className="notifications-dropdown absolute right-0 mt-2 w-64 bg-gray-800 rounded-lg shadow-lg"
                    >
                      <NotificationDropdown
                        notifications={notifications}
                        onNotificationClick={handleNotificationClick}
                      />
                    </div>
                  )}
                </div>
                <div className="relative">
                  <button
                    onClick={toggleMessagesDropdown}
                    className="messages-button relative text-xl"
                  >
                    <FaRegCommentDots />
                    {hasUnreadMessages && (
                      <span className="absolute top-0 right-0 block h-2 w-2 bg-red-600 rounded-full"></span>
                    )}
                  </button>
                  {messagesDropdownOpen && (
                    <div
                      ref={messagesMenuRef}
                      className="messages-dropdown absolute right-0 mt-2 w-64 bg-gray-800 rounded-lg shadow-lg"
                    >
                      <MyMessagesDropDown />
                    </div>
                  )}
                </div>
              </>
            )}
            <div className="relative">
              {currentUser && connected && publicKey ? (
                <button
                  onClick={toggleSidebar}
                  className="menu-button relative text-xl text-white"
                >
                  {publicKey.toBase58().slice(0, 4)}...{publicKey.toBase58().slice(-4)}
                </button>
              ) : (
                <button
                  onClick={toggleSidebar}
                  className="menu-button relative text-xl text-white"
                >
                  ☰ Menu
                </button>
              )}
            </div>
          </div>
        </nav>
      </header>

      {/* Sidebar Modal */}
      <SidebarModal
        isOpen={sidebarOpen}
        onClose={toggleSidebar}
        currentUser={currentUser}
        handleLogout={handleLogout}
      />

      {latestNotification && (
        <NotificationPopup
          message={latestNotification.message}
          onClose={handleNotificationClose}
          read={latestNotification.read}
        />
      )}
    </>
  );
};

export default Header;
